<template>
	<div>
		<!-- -->
		<s-crud
			title="Configuracion estado de Tunel"
			:config="config"
			:filter="filter"
			add
			 @save="save($event)"
			edit 
			remove
			search-input
		> 
			<template v-slot="props">
				<v-container>
					<v-row>
						<v-col>
							<s-select-definition
								label="Estado"
								v-model="props.item.CtsEstatus"
								:def="1359"
							></s-select-definition>
						</v-col>
					</v-row>
                    <v-row>
                        <v-col cols="6" lg="6" md="6" sm="12">
							<s-switch
								v-model="props.item.CtsGroup"
								label="Grupo"
							>			
							</s-switch>
                        </v-col>
                        <v-col cols="6" lg="6" md="6" sm="12">
                            <s-switch
								v-model="props.item.CtsProjectedDate"
								label="Fecha Proyectada"
								
							>
							</s-switch>
                        </v-col>
                        <v-col cols="6" lg="6" md="6" sm="12">
							<s-switch
								v-model="props.item.CtsTemperature"
								label="Temperatura C°"
								
							>
							</s-switch>
                        </v-col>
                        <v-col cols="6" lg="6" md="6" sm="12">
                            <s-switch
								v-model="props.item.CtsHoursEthylene"
								label="Horas Etileno"
								
							>
							</s-switch>
							<!--
								true-value="1"
								false-value="0"
							-->
                        </v-col>
                    </v-row>
				</v-container>
			</template>
		</s-crud>
	</div>
</template>



<script>
	import _sDiscardDefectCultiveService from "@/services/FrozenProduction/ConfigTunnelStatus.js"

	export default {
		data() {
			return {
				CtsProjectedDate: true,
				config: {
					model: {
						CtsID: "ID"
					},
					service: _sDiscardDefectCultiveService,
					headers: [
						{text: "ID", value: "CtsID"},
						{text: "Estado", value: "TypeProcessMadName"},
						{text: "Grupo", value: "CtsGroupName"},
						{text: "Fecha Proyectada", value: "CtsProjectedDateName"},
						{text: "Temperatura C°", value: "CtsTemperatureName"},
						{text: "Horas Etileno", value: "CtsHoursEthyleneName"}

					]
				},
				filter: { TypeProcessMadName: 0},
			}
		},

		methods: {
	
 
			save(item) {

				item.save();
				
			},
		},
	}
</script>